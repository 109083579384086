import { render, staticRenderFns } from "./FileItem.vue?vue&type=template&id=a4f9659a&scoped=true&"
import script from "./FileItem.vue?vue&type=script&lang=js&"
export * from "./FileItem.vue?vue&type=script&lang=js&"
import style0 from "./FileItem.vue?vue&type=style&index=0&id=a4f9659a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4f9659a",
  null
  
)

export default component.exports