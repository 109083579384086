<template>
  <div class="file">
    <template v-if="fileType == 'pdf'">
      <div v-for="i in numPages" :key="i">
        <pdf :src="loadingTask" :page="i"></pdf>
      </div>
    </template>
    <template v-else-if="fileType == 'image'">
      <img @click="showImagePreview(file.fileUrl)" :src="file.fileUrl" />
    </template>
    <template v-else-if="fileType == 'video'">
      <video :src="file.fileUrl"></video>
    </template>
    <template v-else>
      <iframe
        :src="file.fileUrl"
        frameborder="no"
        border="0"
        marginwidth="0"
        marginheight="0"
        allowtransparency="yes"
      ></iframe>
    </template>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import { Toast } from "vant";
import { ImagePreview } from "vant";

export default {
  components: {
    pdf,
  },
  props: {
    file: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loadingTask: null,
      numPages: 0, // pdf文件总页数
    };
  },
  computed: {
    fileType() {
      const { file } = this;
      const pathname = new URL(file.fileUrl).pathname;
      const fileTypeMapping = {
        pdf: /pdf$/i,
        image: /(gif|jpe?g|tiff?|png|webp|bmp)$/i,
        video: /(mp4|avi)$/i,
      };
      for (const type in fileTypeMapping) {
        if (fileTypeMapping[type].test(pathname)) {
          return type;
        }
      }
      return null;
    },
  },
  watch: {
    file: {
      immediate: true,
      handler() {
        this.initFile();
      },
    },
  },
  methods: {
    async getNumPages(url) {
      try {
        const loading = Toast.loading({ duration: 0 });
        this.loadingTask = pdf.createLoadingTask(url);
        var res = await this.loadingTask.promise;
        this.numPages = res.numPages;
        loading.close();
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 预览
    showImagePreview(fileUrl) {
      ImagePreview({
        images: [fileUrl],
        closeable: true,
      });
    },

    async initFile() {
      const { fileType, file } = this;
      switch (fileType) {
        case "pdf":
          await this.getNumPages(file.fileUrl);
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.file {
  padding: 2px 2px;
  img {
    max-width: 100%;
  }
  video,
  iframe {
    max-width: 100%;
  }

  iframe {
    height: 100vh;
  }
}
</style>